<template>
    <div class="modal fade show" id="contactEventSettingsModal" tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="exampleModalLabel" aria-modal="true" style="display: none">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ $t("analytics_contactEventsSettingsTitle") }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="kt-section kt-section--first">
                        <div class="kt-section__body">
                            <div class="form-group row validated mb-6">
                                <div class="col-md-6">
                                    <label for="contactEventSetting_riskLevel">{{ $t("analytics_contactEventsSettingRiskLevel") }}</label>
                                    <div class="input-group contactParamInput">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"><i class="la la-warning"></i></span>
                                        </div>
                                        <input v-model="vRiskLevel" @input="$v.vRiskLevel.$touch()" type="number" min="0" max="100" step="5" class="form-control" id="analytics_contactEventsRisk" :placeholder="$t('analytics_contactEventsRisk')" />
                                        <div class="input-group-append">
                                            <span class="input-group-text">%</span>
                                        </div>
                                    </div>
                                    <div v-if="!$v.vRiskLevel.required" class="invalid-feedback">
                                        {{ $t("error_fieldIsRequired") }}
                                    </div>
                                    <div v-else-if="!$v.vRiskLevel.integer" class="invalid-feedback">
                                        {{ $t("error_invalidNumber") }}
                                    </div>
                                    <div v-else-if="!$v.vRiskLevel.between" class="invalid-feedback">
                                        {{ $t("error_betweenValueMsg", [$v.vRiskLevel.$params.between.min, $v.vRiskLevel.$params.between.max]) }}
                                    </div>
                                </div>
                                <span class="form-text text-muted col-sm-12">{{ $t("analytics_contactEventsSettingRiskLevelDetailInfo") }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button id="contactEventSettingsModal_saveButton" type="button" @click="saveContactEventSettings" class="btn btn-brand kt-margin-r-5">
                        {{ $t("common_save") }}
                    </button>
                    <button id="contactEventSettingsModal_cancelButton" type="button" class="btn btn-secondary" data-dismiss="modal">
                        {{ $t("common_cancel") }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { required, integer, between } from "vuelidate/lib/validators";


export default {
    data() {
        return {
            vRiskLevel: 50
        };
    },
    created: function() {
        console.log("Component(ContactEventSettingsModal)::created() - called");
    },
    mounted: function() {
        console.log("Component(ContactEventSettingsModal)::mounted() - called");
    },
    destroyed: function() {
        console.log("Component(ContactEventSettingsModal)::destroyed() - called");
    },
    validations: {
    // -- validations -- List of controls to apply to validate a form.
    // --
        vRiskLevel: {
            integer,
            required,
            between: between(0, 100)
        },
    },
    watch: {
    // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
    // --
        currentContacEventSetting: function(obj) {
            console.log("Component(ContactEventSettingsModal)::watch(currentContacEventSetting) called with :", obj);
            this.vRiskLevel = (obj ? obj.riskLevel : 50);
        }
    },
    computed: {
    // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
    // To compose new data from existing datasource
    // To reference a value from the template (cached value)
    // --
        ...mapGetters([
            "currentContacEventSetting"
        ])
    },
    methods: {
        ...mapActions([
            "updateContactEventSettings"
        ]),
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---

        saveContactEventSettings() {
            console.log("Component(ContactEventSettingsModal)::saveContactEventSettings() - called");
            if (this.currentContacEventSetting) {
                const payload = {
                    siteId: this.currentContacEventSetting.site,
                    contactEventSettingId: this.currentContacEventSetting.id,
                    riskLevel: this.vRiskLevel
                }
                this.updateContactEventSettings(payload);
            }

            // Hide settings modal to display contact events page
            $("#contactEventSettingsModal").modal("hide");
        }
    },
    components: {
    // -- Components -- List of local components used in the current template
    // --
    }
};
</script>

<style scoped>
.contactParamInput {
    width: 150px;
}
</style>
