<template>
    <div class="c-select dropdown dropdown-inline">
        <button type="button" data-toggle="dropdown" aria-haspopup="true" class="btn btn-sm btn-outline-brand kt-margin-l-10 paddingTop8">
            <i class="fa flaticon2-settings"></i>
            {{ $t("analytics_contactEventsColumns") }}
        </button>
        <!--begin::Dropdown Menu-->
        <div id="select-columns"  data-scroll="true" data-suppress-scroll-x="false" data-swipe-easing="false" class="dropdown-menu dropdown-menu-right">
            <!--begin::Navigation-->
            <div class="d-flex flex-row" style="width: fit-content">
                <ul v-for="(options, index) in columnsOptions" :key="index" class="navi form-group py-2 pl-3 m-0 selectColWidth">
                    <li v-for="column in options" :key="column.field" class="navi-item">
                        <label :class="['checkbox kt-checkbox kt-checkbox--bold  kt-checkbox--brand selectColText', column.disabled ? 'checkbox-disabled' : '']">
                            <input type="checkbox" :id="column.field" :value="column.field" v-model="selectedColumns" :disabled="column.disabled">
                            <span></span>  {{column.name}}
                        </label>
                    </li>
                </ul>
            </div>
            <!--end::Navigation-->
        </div>
        <!--end::Dropdown Menu-->
    </div>
</template>

<script>

import _ from "lodash";

export default {
    name: "selectColumns",
    props: {
        columns: Array,
        value: Array
    },
    data() {
        return {
            selectedColumns: null,
            columnsOptionsSize: 10
        };
    },
    mounted: function() {
        console.log("Component(selectColumns)::mounted() - called");
        $('[data-toggle="tooltip"]').tooltip({ trigger: "hover" });

        $('#select-columns').click(function(event){
            //console.log("Component(selectColumns)::mounted() - event select-columns", event);
            event.stopPropagation();
        });
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
        columnsOptions: {
            immediate: true,
            handler: function(data) {
                console.log("Component(selectColumns)::watch(columnsOptions) - called with:", data);
                if (!this.selectedColumns) {
                    // Get an array of column name which must be selected at the first time
                    const inputCols = _.flatten(data).filter(elt => elt.selectedAtFirst).map(col => col.field);
                    this.selectedColumns = inputCols;
                }
            }
        },

        filteredColumns: {
            immediate: true,
            handler: function(data){
                console.log("Component(selectColumns)::watch(filteredColumns) - called with:", data);
                if (data) {
                    // dont use timeout for the first time
                    if (!this.timeout) {
                        // emit filtered column value
                        this.$emit('input', data);
                        // toggle timout
                        this.timeout = true;
                    } else {
                        // the following technique is used to avoid emmiting multipe values in short time
                        clearTimeout(this.timeout);
                        // store a reference to this
                        const self = this;

                        this.timeout = setTimeout(function() {
                            // emit filtered column value
                            self.$emit('input', data);
                        }, 20);
                    }
                }
            }
        }

    },
    methods: {
        // -- Methods -- Property to perform an action.
        // ---
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --

        // columns to display on dropdown menu
        columnsOptions: function(){
            console.log("Component(selectColumns)::computed(columnsOptions) - called");

            let columns = this.columns.map(col => ({field: col.field, name: col.headerName, selectedAtFirst: col.selectedAtFirst, disabled: false}));
            /* To force to keep one selected choice
            if (this.selectedColumns && this.selectedColumns.length === 1) {
                columns = columns.map(col => {
                    if (col.field === this.selectedColumns[0]) {
                        return {...col, disabled: true};
                    }
                    return col;
                });
            }
            */
            columns = _.chunk(columns, this.columnsOptionsSize);
            return columns;
        },

        // columns to display on table
        filteredColumns: function(){
            console.log("Component(selectColumns)::computed(filteredColumns) - called");

            if(!this.selectedColumns || this.selectedColumns.length === 0) {
                return [];
            }
            return this.columns.filter(col => this.selectedColumns.includes(col.field));
        }

    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
    }
};
</script>

<style scoped>
.selectColWidth {
    width: 260px;
}
.selectColText {
    color: #6c7293;
    font-weight: 500;
}
</style>

<style>
/* width */
.c-select ::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* Track */
.c-select ::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.c-select ::-webkit-scrollbar-thumb {
  background: #aaa;
  border-radius: 4px;
}

/* Handle on hover */
.c-select ::-webkit-scrollbar-thumb:hover {
  background: #888;
}
</style>