<template>
    <div id="contactEventsList" class="baseTemplate">
        <!-- begin:: mobile menu -->
        <app-mobilemenu></app-mobilemenu>
        <!-- end:: mobile menu -->

        <div class="kt-grid kt-grid--hor kt-grid--root">
            <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                <!-- begin:: home menu -->
                <app-siteconfigurationmenu activeItem="contactEvents"></app-siteconfigurationmenu>
                <!-- end:: home menu -->

                <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper">
                    <!-- begin:: header -->
                    <app-header></app-header>
                    <!-- end:: header -->

                    <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                        <!-- begin:: Content Head -->
                        <div class="kt-subheader kt-grid__item">
                            <app-sitetitle subHeaderDescKey="analytics_contactEventsSubTitle"></app-sitetitle>
                            <div class="kt-subheader__toolbar">
                                <div class="kt-subheader__wrapper">
                                </div>
                            </div>
                        </div>
                        <!-- end:: Content Head -->

                        <app-accesserrorportlet ref="accessErrorPortlet" v-show="!accessGranted" activeItem="contactEvents" :siteId="siteId"></app-accesserrorportlet>

                        <!-- begin:: Content -->
                        <div v-if="accessGranted" class="kt-content kt-grid__item kt-grid__item--fluid">

                            <div class="row" v-show="isInitSelect2">

                                <div class="col-md-12">

                                    <!--begin::portletParams-->
                                    <div class="kt-portlet">

                                        <div class="kt-portlet__head kt-portlet__head--lg kt-portlet__head--break-sm">
                                            <!--begin::headLabelParams-->
                                            <div class="kt-portlet__head-label">
                                                <span class="kt-portlet__head-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                            <polygon points="0 0 24 0 24 24 0 24"/>
                                                            <path d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"/>
                                                            <path d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z" fill="#000000" fill-rule="nonzero"/>
                                                        </g>
                                                    </svg>
                                                </span>
                                                <h3 class="kt-portlet__head-title">
                                                    {{ $t("analytics_contactEventsParam") }}
                                                </h3>
                                            </div>
                                            <!--end::headLabelParams-->
                                            <!--begin::headToolbarParams-->
                                            <div class="kt-portlet__head-toolbar">
                                                <div class="kt-portlet__head-wrapper">
                                                    <div class="kt-portlet__head-toolbar">
                                                        <button @click="onContactEventSettings" id="contactEvents_settings" class="btn btn-sm btn-outline-brand kt-margin-l-10 paddingTop8">
                                                            <i class="fa flaticon2-settings kt-margin-r-5"></i>
                                                            {{ $t("analytics_contactEventsSettingsButton") }}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <!--end::headToolbarParams-->

                                        </div>

                                        <!--begin::Form-->
                                        <form class="kt-form" novalidate="novalidate">

                                            <div class="kt-portlet__body">

                                                <!--begin:InputPeriod-->
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="input-group date col-md-8 validated" style="margin-left: -10px;">
                                                            <label class="col-form-label" style=" margin-right: 10px; ">{{ $t("analytics_contactEventsFromStartDate") }}</label>
                                                            <div class="period input-group-prepend">
                                                                <span> </span>
                                                                <span class="input-group-text">
                                                                    <i class="la la-calendar"></i>
                                                                </span>
                                                            </div>
                                                            <input type="text" id="contactEvents_periodStartTimeDatePicker" :placeholder="$t('analytics_contactEventsStartDatePlaceholder')" class="form-control" />
                                                            <label class="col-form-label" style=" margin-left: 10px; margin-right: 10px; ">{{ $t("analytics_contactEventsToEndDate") }}</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">
                                                                    <i class="la la-calendar"></i>
                                                                </span>
                                                            </div>
                                                            <input type="text" id="contactEvents_periodEndTimeDatePicker" :placeholder="$t('analytics_contactEventsEndDatePlaceholder')" class="form-control" />
                                                            <div v-if="!isTwoDateSelected" class="invalid-feedback" style=" margin-left: 4px; ">
                                                                {{ $t("error_twoDatesRequired") }}
                                                            </div>
                                                            <div v-if="!isCorrectEndDate" class="invalid-feedback" style=" margin-left: 4px; ">
                                                                {{ $t("error_endDateIsNotCorrect") }}
                                                            </div>
                                                            <div v-if="isPeriodExceedOneMonth()" class="invalid-feedback" style=" margin-left: 4px; ">
                                                                {{ $t("error_periodOneWeekIsNotCorrect") }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!--end:InputPeriod-->

                                                <div class="kt-separator kt-separator--border-dashed"></div>

                                                <!--begin:InputRadioAsset-->
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="form-group validated">
                                                            <label class="colorGeonotif">{{ $t("analytics_contactEventsTrackingSelectAssets") }} <br /></label>
                                                            <div class="kt-radio-list" style=" margin-left: 10px;">
                                                                <div class="row mtop20">
                                                                    <label class="kt-radio kt-radio--bold kt-radio--brand">
                                                                        <input id="contactEvents_anythingRadio" type="radio" v-model="vSelectedAsset" name="triggerCondition" value="anything" checked /> {{ $t("analytics_contactEventsTrackingAnyone") }}
                                                                        <span></span>
                                                                    </label>
                                                                </div>
                                                                <div class="row mtop20">
                                                                    <label class="kt-radio kt-radio--bold kt-radio--brand">
                                                                        <input id="contactEvents_assetsRadio" type="radio" v-model="vSelectedAsset" name="triggerCondition" value="asset" /> {{ $t("analytics_contactEventsTrackingAsset") }}
                                                                        <span></span>
                                                                    </label>
                                                                    <select id="contactEvents_assetSelect2" multiple="multiple" class="form-control kt-select2" disabled>
                                                                        <!--option></option-->
                                                                    </select>
                                                                    <div v-if="vSelectedAsset==='asset' && vAssetHistories.length===0" class="invalid-feedback assetSelect2Error">
                                                                        {{ $t("error_fieldIsRequired") }}
                                                                    </div>
                                                                </div>
                                                                <br />
                                                                <div class="row">
                                                                    <label class="kt-radio kt-radio--bold kt-radio--brand">
                                                                        <input id="contactEvents_categoriesRadio" type="radio" v-model="vSelectedAsset" name="triggerCondition" value="assetCategory" /> {{ $t("analytics_contactEventsTrackingCategory") }}
                                                                        <span></span>
                                                                    </label>
                                                                    <select id="contactEvents_categorySelect2" multiple="multiple" class="form-control kt-select2" disabled>
                                                                        <!--option></option-->
                                                                    </select>
                                                                    <div v-if="vSelectedAsset==='assetCategory' && vCategories.length===0" class="invalid-feedback assetCategorySelect2Error">
                                                                        {{ $t("error_fieldIsRequired") }}
                                                                    </div>
                                                                </div>
                                                                <div class="row mtop20">
                                                                    <label class="kt-radio kt-radio--bold kt-radio--brand">
                                                                        <input id="contactEvents_subcategoriesRadio" type="radio" v-model="vSelectedAsset" name="triggerCondition" value="assetSubcategory" /> {{ $t("analytics_contactEventsTrackingSubcategory") }}
                                                                        <span></span>
                                                                    </label>
                                                                    <select id="contactEvents_subcategorySelect2" multiple="multiple" class="form-control kt-select2" disabled>
                                                                        <!--option></option-->
                                                                    </select>
                                                                    <div v-if="vSelectedAsset==='assetSubcategory' && vSubcategories.length===0" class="invalid-feedback assetCategorySelect2Error">
                                                                        {{ $t("error_fieldIsRequired") }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!--end:InputRadioAsset-->

                                                <div class="kt-separator kt-separator--border-dashed mtop0"></div>

                                                <!--begin:ContactFilters-->
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="form-group validated">
                                                            <label class="colorGeonotif">{{ $t("analytics_contactEventsFiltersTitle") }} <br /></label>
                                                        </div>
                                                        <div class="form-group row validated mbottom0">
                                                            <div class="col-md-6">
                                                                <label for="contactEvents_minimalDuration">{{ $t("analytics_contactEventsMinimalDuration") }}</label>
                                                                <div class="input-group contactParamInput">
                                                                    <div class="input-group-prepend">
                                                                        <span class="input-group-text"><i class="la la-hourglass-end"></i></span>
                                                                    </div>
                                                                    <input v-model="vMinimalDuration" @input="$v.vMinimalDuration.$touch()" type="number" min="5" max="360" class="form-control" id="contactEvents_minimalDuration" :placeholder="$t('analytics_contactEventsMinimalDurationPlaceHolder')" />
                                                                    <div class="input-group-append">
                                                                        <span class="input-group-text">{{ $t("common_minutes")}}</span>
                                                                    </div>
                                                                </div>
                                                                <div v-if="!$v.vMinimalDuration.required" class="invalid-feedback">
                                                                    {{ $t("error_fieldIsRequired") }}
                                                                </div>
                                                                <div v-else-if="!$v.vMinimalDuration.integer" class="invalid-feedback">
                                                                    {{ $t("error_invalidNumber") }}
                                                                </div>
                                                                <div v-else-if="!$v.vMinimalDuration.between" class="invalid-feedback">
                                                                    {{ $t("error_betweenValueMsg", [$v.vMinimalDuration.$params.between.min, $v.vMinimalDuration.$params.between.max]) }}
                                                                </div>
                                                                <span class="form-text text-muted"> {{ $t("analytics_contactEventsMinimalDurationDetailInfo") }}</span>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <label for="contactEvents_criticalDistance">{{ $t("analytics_contactEventsCriticalDistance") }}</label>
                                                                <div class="input-group contactParamInput">
                                                                    <div class="input-group-prepend">
                                                                        <span class="input-group-text"><i class="la la-arrows-h"></i></span>
                                                                    </div>
                                                                    <input v-model="vCriticalDistance" @input="$v.vCriticalDistance.$touch()" type="number" step="0.5" min="0.5" max="10" class="form-control" id="contactEvents_criticalDistance" :placeholder="$t('analytics_contactEventsCriticalDistancePlaceHolder')" />
                                                                    <div class="input-group-append">
                                                                        <span class="input-group-text">{{ $t("common_meters")}}</span>
                                                                    </div>
                                                                </div>
                                                                <div v-if="!$v.vCriticalDistance.required" class="invalid-feedback">
                                                                    {{ $t("error_fieldIsRequired") }}
                                                                </div>
                                                                <div v-else-if="!$v.vCriticalDistance.decimal" class="invalid-feedback">
                                                                    {{ $t("error_invalidNumber") }}
                                                                </div>
                                                                <div v-else-if="!$v.vCriticalDistance.between" class="invalid-feedback">
                                                                    {{ $t("error_betweenValueMsg", [$v.vCriticalDistance.$params.between.min, $v.vCriticalDistance.$params.between.max]) }}
                                                                </div>
                                                                <span class="form-text text-muted"> {{ $t("analytics_contactEventsCriticalDistanceDetailInfo") }}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!--end:ContactFilters-->

                                            </div>

                                            <div class="kt-portlet__foot p-4">
                                                <div class="kt-form__actions kt-form__actions--right">
                                                    <button id="contactEvents_SearchButton" @click="onSearchButton" type="button" :disabled="$v.$invalid || !isCorrectEndDate || !isTwoDateSelected || !isCorrectContactSelected() || isPeriodExceedOneMonth()" class="btn btn-brand kt-margin-r-5">
                                                        <i class="la la-search"></i>
                                                        {{ $t("common_search") }}
                                                    </button>
                                                </div>
                                            </div>

                                        </form>
                                        <!--end::Form-->
                                    </div>
                                    <!--end::portletParams-->

                                    <!--begin::portletContactsList-->
                                    <div v-show="contactEvents" class="kt-portlet">

                                        <!--begin::headPortletContactsList-->
                                        <div class="kt-portlet__head kt-portlet__head--lg kt-portlet__head--noborder kt-portlet__head--break-sm">
                                            <!--begin::headLabel-->
                                            <div class="kt-portlet__head-label">
                                                <span class="kt-portlet__head-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                            <polygon points="0 0 24 0 24 24 0 24"/>
                                                            <path d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"/>
                                                            <path d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z" fill="#000000" fill-rule="nonzero"/>
                                                        </g>
                                                    </svg>
                                                </span>
                                                <h3 class="kt-portlet__head-title">
                                                    {{ $t("analytics_contactEventsList") }}
                                                </h3>
                                            </div>
                                            <!--end::headLabel-->
                                            <!--begin::headToolbar-->
                                            <div v-if="!isEmptyList" class="kt-portlet__head-toolbar">
                                                <div class="kt-portlet__head-wrapper">
                                                    <div class="kt-portlet__head-toolbar">
                                                        <div class="row align-items-center">
                                                            <div class="col-md-12 kt-margin-b-20-tablet-and-mobile">
                                                                <div class="kt-input-icon kt-input-icon--left">
                                                                    <input type="text" :placeholder="$t('common_searchByName')" v-model="searchedValue" id="contactEvents_searchInput" class="searchInputDatatable form-control form-control-sm width270" />
                                                                    <span class="kt-input-icon__icon kt-input-icon__icon--left">
                                                                        <span><i class="la la-search"></i></span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- begin::select show/hide table columns -->
                                                <app-select-columns id="contactEvents_displayColumnSelect" :columns="columnDefs" v-model="filteredColumns" class="toggleSelectedColumns"/>
                                                <!-- end::select show/hide table columns -->
                                                <button @click="exportContacEventsDataToExcel" id="contactEvents_exportXLSButton" class="exportXLSXButton btn btn-sm btn-outline-success kt-margin-l-10 paddingTop8">
                                                    <i class="fa fa-file-excel kt-margin-r-5"></i>
                                                    {{ $t("common_exportFromXLSFile") }}
                                                </button>
                                            </div>
                                            <!--end::headToolbar-->
                                        </div>
                                        <!--end::headPortlet-->

                                        <!--begin emptyList component-->
                                        <app-emptylist targetObjectName="CONTACTEVENTS" mainTextKey="analytics_contactEventsNotFound" subTextKey="analytics_contactEventsNotFoundSubLabel" imgName="empty_role.svg" v-bind:isDisplayed="isEmptyList"></app-emptylist>
                                        <!--end emptyList component-->

                                        <div class="kt-portlet__body kt-portlet__body--fit">
                                            <!--begin: Datatable -->
                                            <div class="kt-datatable" id="contactEvents_datatable"></div>
                                            <!--end: Datatable -->
                                        </div>
                                    </div>
                                    <!--end::portletContactsList-->

                                </div>

                            </div>

                        </div>
                        <!-- end:: Content -->
                    </div>

                    <!-- begin:: Footer -->
                    <app-footer></app-footer>
                    <!-- end:: Footer -->
                    <!-- begin:: modal -->
                    <app-contacteventsettingsmodal ref="contacteventsettingsmodal"></app-contacteventsettingsmodal>
                    <!-- end:: modal -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { required, integer, decimal, between } from "vuelidate/lib/validators";
import i18n from "../../i18n";
import SiteTitle from "../site/widgets/sitetitle.vue";
import AccessErrorPortlet from "../utils/accesserrorportlet.vue";
import EmptyList from "./../utils/emptylist.vue";
import moment from "moment";
import commonVueHelper from "../../helpers/commonVueHelper";
import selectColumnsVue from '../utils/selectColumns.vue';
import ContactEventSettingsModal from "../modals/contacteventsettingsmodal"

const meterUnitTranslated = " " + i18n.t("common_shortMeter");
const hourUnitTranslated = " " + i18n.t("common_shortHour");
const minuteUnitTranslated = " " + i18n.t("common_shortMinute");
const secondUnitTranslated = " " + i18n.t("common_shortSecond");
const xlMomentDateFormat = commonVueHelper.getXlDateTimezoneFormat();

const columnWidthByName = {
    unit: 130,
    tagType: 85,
    date: 160,
    distance: 85,
    duration: 110,
    risk: 75
};

export default {
    data() {
        return {
            siteId           : this.$route.params.siteId,
            isMounted        : false,

            // Search parameters portlet
            isCorrectEndDate : true,
            isTwoDateSelected: false,
            vEndTime         : new Date(),
            vStartTime       : this.getMinDate(new Date()),
            maxEndTime       : commonVueHelper.getToDayPlusXmin(5),   // today + 5min
            isInitSelect2    : false,
            vSelectedAsset   : "anything",
            vAssetHistories  : [],                                    // Array of AssetHistory id
            vCategories      : [],
            vSubcategories   : [],
            vMinimalDuration : 5,                                     // integer in minutes,
            vCriticalDistance: 5,                                     // integer in meters

            // Datatable portlet
            isEmptyList      : false,

            // Columns choice
            filteredColumns  : [],

            // Datatable
            contactEventsData     : null,
            contactEventsDataTable: null,
            searchedValue         : ""
        };
    },
    created: function() {
        console.log("Component(contactEvents)::created() - called");
    },
    mounted: function() {
        console.log("Component(contactEvents)::mounted() - called");
        KTLayout.init();
        this.isMounted = true;
        // Get the list of site assetcategories
        this.getSiteAssetCategories(this.siteId);
        // Initialzation of date picker
        this.initStartDateTimePicker();
        this.initEndDateTimePicker();
    },
    destroyed: function() {
        console.log("Component(contactEvents)::destroyed() - called");
        this.resetContactEventSettingsState();
        this.resetContactEventsState();
        this.destroyDateTimePicker();
        this.destroyKTDatatable();
        this.contactEventsData = null;
    },
    validations: {
        // -- validations -- List of controls to apply to validate a form.
        // --
        vMinimalDuration: {
            integer,
            required,
            between: between(5, 360) // [5min, 6h]
        },
        vCriticalDistance: {
            decimal,
            required,
            between: between(0.5, 10) // [50cm, 10m]
        }
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --

        // Change asset categories
        siteAssetCategories: {
            //immediate: true,
            //deep: true,
            handler(newCategories) {
                console.log("Component(contactEvents)::watch(siteAssetCategories) - called with:", newCategories);
                // Get all Personally Identifiable Information (PII) which can be selected for contact events
                this.getAssetHistories({siteId: this.siteId});
            }
        },

        // Change asset histories
        assetHistories: {
            handler(newValue) {
                console.log("Component(contactEvents)::watch(assetHistories) called with:", newValue);
                if (newValue) {
                    this.initPeopleAssetSelect2(newValue);
                    this.initPeopleCategoriesAndSubcategories();
                }
                //Get the contact event setting of a user logged site
                this.getSiteContactEventSettings({siteId: this.siteId});
            }
        },

        // Change button radio selection
        vSelectedAsset: function(newSelectedAsset) {
            console.log("Component(contactEvents)::watch(vSelectedAsset) - called with " + newSelectedAsset);
            switch (newSelectedAsset) {
                case "anything":
                    this.enableSelectedAnyone();
                    break;
                case "asset":
                    this.enableSelectedAsset();
                    break;
                case "assetCategory":
                    this.enableSelectedCategory();
                    break;
                case "assetSubcategory":
                    this.enableSelectedSubcategory();
                    break;
                default:
                    // Defense
                    this.vSelectedAsset = "anything";
                    this.enableSelectedAnyone();
            }
        },

        // Change resulting contact event search
        contactEvents: {
            immediate: true,
            deep: true,
            handler(newValue) {
                console.log("Component(contactEvents)::watch(contactEvents) called with:", newValue);
                if (newValue) {
                    this.initContactEventsData();
                    this.initKTDataTable();
                }
            }
        },

        user: function(user) {
            if (user) {
                console.log("Component(contactEvents)::watch(user) called with :", user);
                // Translate search parameters
                this.initPeopleAssetSelect2(this.assetHistories);
                this.initPeopleCategoriesAndSubcategories();
                // Translate datatable
                this.initContactEventsData();
                this.initKTDataTable();
            }
        },

        filteredColumns: {
            immediate: true,
            deep: true,
            handler(data) {
                //console.log("Component(contactEvents)::watch(filteredColumns) called with :", data);
                if (data) {
                    this.refreshFilteredColumns();
                }
            }
        },

        currentContacEventSetting: function(setting) {
            console.log("Component(contactEvents)::watch(currentContacEventSetting) called with :", setting);
            if (setting && this.contactEventsDataTable) {
                // Reload datatable to update risk level display
                this.contactEventsDataTable.reload();
            }
        }

    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters(["siteAssets", "siteAssetCategories", "contactEvents", "assetHistories", "user", "currentContacEventSetting"]),

        accessGranted: function() {
            console.log("Component(contactEvents)::computed(accessGranted) - called");
            let isAccessGranted = true;
            if (this.isMounted && this.$refs.accessErrorPortlet) {
                isAccessGranted = this.$refs.accessErrorPortlet.isAccessGranted();
            }
            return isAccessGranted;
        },

        columnDefs: function(){
            console.log("Component(contactEvents)::computed(columnDefs) - called");
            return  ([
                {field: "rootCategory",         headerName: i18n.t("analytics_contactEventsAssetCategory"),             selectedAtFirst: true},
                {field: "rootSubcategory",      headerName: i18n.t("analytics_contactEventsAssetSubcategory"),          selectedAtFirst: false},
                {field: "rootAssetUnit",        headerName: i18n.t("analytics_contactEventsAssetUnit"),                 selectedAtFirst: false},
                {field: "rootTagSerialNumber",  headerName: i18n.t("analytics_contactEventsAssetTagSN"),                selectedAtFirst: false},
                {field: "rootTagType",          headerName: i18n.t("analytics_contactEventsAssetTagType"),              selectedAtFirst: false},
                {field: "category",             headerName: i18n.t("analytics_contactEventsPrimaryContactCategory"),    selectedAtFirst: true},
                {field: "subcategory",          headerName: i18n.t("analytics_contactEventsPrimaryContactSubcategory"), selectedAtFirst: false},
                {field: "assetUnit",            headerName: i18n.t("analytics_contactEventsPrimaryContactUnit"),        selectedAtFirst: false},
                {field: "tagSerialNumber",      headerName: i18n.t("analytics_contactEventsPrimaryContactTagSN"),       selectedAtFirst: false},
                {field: "tagType",              headerName: i18n.t("analytics_contactEventsPrimaryContactTagType"),     selectedAtFirst: false},
                {field: "risk",                 headerName: i18n.t("analytics_contactEventsRisk"),                      selectedAtFirst: true},
                {field: "startDate",            headerName: i18n.t("analytics_contactEventsStartDate"),                 selectedAtFirst: true},
                {field: "endDate",              headerName: i18n.t("analytics_contactEventsEndDate"),                   selectedAtFirst: true},
                {field: "geofences",            headerName: i18n.t("analytics_contactEventsGeofences"),                 selectedAtFirst: true},
                {field: "minimalDistance",      headerName: i18n.t("analytics_contactEventsMinimalDistance"),           selectedAtFirst: true},
                {field: "duration0to5m",        headerName: i18n.t("analytics_contactEventsDuration0to5m"),             selectedAtFirst: true},
                {field: "duration5to10m",       headerName: i18n.t("analytics_contactEventsDuration5to10m"),            selectedAtFirst: true},
                {field: "averageDistance",      headerName: i18n.t("analytics_contactEventsAverageDistance"),           selectedAtFirst: true},
                {field: "totalDuration",        headerName: i18n.t("analytics_contactEventsTotalDuration"),             selectedAtFirst: true}
            ]);
        }
    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        ...mapActions(["getSiteAssetCategories", "getContactEvents", "getAssetHistories", "resetContactEventsState", "getSiteContactEventSettings", "resetContactEventSettingsState"]),

        loadAssetHistories() {
            if (this.vStartTime && this.vEndTime) {
                const payload = {
                    siteId: this.siteId,
                    startDate: this.vStartTime.getTime(),
                    endDate: this.vEndTime.getTime()
                }
                this.getAssetHistories( { siteId: this.siteId } );
            }
        },

        initStartDateTimePicker() {
            var self = this;
            this.isTwoDateSelected = (self.vStartTime && self.vEndTime);

            let selector = $("#contactEvents_periodStartTimeDatePicker");
            //initDateTimePicker(selector, position, currentDate, startDate, endDate) {
            commonVueHelper.initDateTimePicker(
                selector,
                "bottom-right",
                self.vStartTime,    // currentDate
                null,               // startDate
                self.maxEndTime     // endDate
            );
            selector.on("changeDate", event => {
                self.isCorrectEndDate = true;
                if (event.date) {
                    self.vStartTime = new Date(event.date.valueOf());
                    if (self.vEndTime && self.vStartTime >= self.vEndTime) {
                        self.isCorrectEndDate = false;
                    }
                } else {
                    self.vStartTime = null;
                }
                self.isTwoDateSelected = (self.vStartTime && self.vEndTime);
            });
            selector.on("focusout", event => {
                if (!selector.val()) {
                    self.vStartTime = null;
                }
                self.isTwoDateSelected = (self.vStartTime && self.vEndTime);
                if (!self.isTwoDateSelected) {
                    self.isCorrectEndDate = true;
                }
            });
        },

        // init end date picker
        initEndDateTimePicker() {
            var self = this;

            let selector = $("#contactEvents_periodEndTimeDatePicker");
            commonVueHelper.initDateTimePicker(
                selector,
                "bottom-right",
                self.vEndTime,  // currentDate
                null,           // startDate
                self.maxEndTime // endDate
            );
            selector.on("changeDate", event => {
                self.isCorrectEndDate = true;
                if (event.date) {
                    self.vEndTime = new Date(event.date.valueOf());
                    if (self.vStartTime && self.vEndTime <= self.vStartTime) {
                        self.isCorrectEndDate = false;
                    }
                } else {
                    self.vEndTime = null;
                }
                self.isTwoDateSelected = (self.vStartTime && self.vEndTime);
                if (!self.isTwoDateSelected) {
                    self.isCorrectEndDate = true;
                }
            });
            selector.on("focusout", event => {
                if (!selector.val()) {
                    self.vEndTime = null;
                }
                self.isTwoDateSelected = (self.vStartTime && self.vEndTime);
                if (!self.isTwoDateSelected) {
                    self.isCorrectEndDate = true;
                }
            });
        },

        // destroy the two DateTimePicker (to) and (from)
        destroyDateTimePicker() {
            commonVueHelper.destroyDateTimePicker($("#contactEvents_periodStartTimeDatePicker"));
            commonVueHelper.destroyDateTimePicker($("#contactEvents_periodEndTimeDatePicker"));
        },

        // check if the period (from start date to end date) exceeds a month
        isPeriodExceedOneMonth() {
            let isPeriodExceedOneMonth = false;
            if (this.vStartTime && this.vEndTime) {
                let minDate = this.getMinDate(this.vEndTime);
                if (this.vStartTime < minDate) {
                    isPeriodExceedOneMonth = true;
                } else {
                    isPeriodExceedOneMonth = false;
                }
            }
            return isPeriodExceedOneMonth;
        },

        // get minimal date which is (endDate - 1 week)
        getMinDate(endDate) {
            let minDate = new Date(endDate);
            // date - 1 month
            //minDate.setMonth(minDate.getMonth() - 1);
            // date - 1 week
            minDate.setDate(minDate.getDate() - 7);
            return minDate;
        },

        initPeopleAssetSelect2(assetHistories) {
            console.log("Component(contactEvents)::initPeopleAssetSelect2() - called", assetHistories);
            var self = this;
            const selector = $("#contactEvents_assetSelect2");
            // Init Select2 data of asset
            let dataAssetSelect2 = assetHistories.filter(history => (history.isPeopleCategory && history.tagMacAddress)).map( (peopleHistory) => {
                return {
                    id: peopleHistory.id,
                    text: peopleHistory.assetName,
                    detailText: self.getAssetDetailLabel(peopleHistory.assetUnit, peopleHistory.tagSerialNumber, peopleHistory.tagTypeName, peopleHistory.categoryName, peopleHistory.isDefaultCategory, peopleHistory.subcategoryName, peopleHistory.isDefaultSubcategory)
                }
            });
            // Remove duplicate entries
            dataAssetSelect2 = _.uniqWith(
                dataAssetSelect2,
                (asset1, asset2) => (asset1.text === asset2.text && asset1.detailText === asset2.detailText)
            );
            // Ascending sort order Select2 data by text
            dataAssetSelect2 = _.orderBy(
                dataAssetSelect2,
                [asset => asset.text.toLowerCase() + asset.detailText.toLowerCase()],
                ["asc"]
            );
            // Init assets select2
            commonVueHelper.destroySelect2(selector);

            selector
                .select2({
                    placeholder: i18n.t("analytics_contactEventsSelectAsset"),
                    width: "50%",
                    data: dataAssetSelect2,
                    //minimumResultsForSearch: -1,
                    escapeMarkup: function(m) {
                        return m;
                    },
                    templateResult: this.formatAssetItem,
                    templateSelection: this.formatAssetItem,
                    containerCssClass: "geonotifSelect2"
                })
                .val(this.vAssetHistories)
                .trigger("change")
                // emit event on change.
                .on("change", function() {
                    // Store category index to retrieve associated subcategory
                    self.vAssetHistories = $(this).val();
                });
        },

        /**
         * Initialization of the list of asset/asset history categories and subcategories to display in select2
         */
        initPeopleCategoriesAndSubcategories() {
            var self = this;
            if (self.siteAssetCategories && self.assetHistories) {
                console.log("Component(contactEvents)::initPeopleCategoriesAndSubcategories() - called");
                // --- Select2 Categories initialization ---
                // Init asset category list for people with all asset categories and old asset history categories (some categories may have been renamed)
                const peopleAssetCategories = self.siteAssetCategories.filter(category => category.isPeopleCategory).map(peopleCategory => peopleCategory.name);;
                const peopleAssetHistories = self.assetHistories.filter(history => history.isPeopleCategory).map(peopleHistory => peopleHistory.categoryName);
                const peopleCategoryNames = _.union(peopleAssetCategories, peopleAssetHistories);
                // Init asset subcategory list for people with all asset subcategories and old asset history subcategories (some categories may have been renamed)
                const assetSubcategoriesByCategoryName = self.siteAssetCategories.reduce(
                    (accumulator, currentCategory) => ({...accumulator, [currentCategory.name]: currentCategory.subcategories}),
                    {}
                );
                // Init the select2 categories
                self.initPeopleCategorySelect2(peopleCategoryNames);

                // --- Select2 Subcategories initialization ---
                // List of subcategory names by category name as {"category1": ["subcat1", "subcat2", ..], "category2": ["subcat10", ...], ...}
                let peopleSubcategoryNamesByCategoryName = {};
                for (let i=0; i<peopleCategoryNames.length; i++) {
                    // Search subcategories of peopleCategoryNames[i] in peopleAssetCategories and peopleAssetHistories
                    const catName = peopleCategoryNames[i];
                    // Extract the array of asset HISTORY subcategory names of the current category name
                    const peopleAssetSubcatHistories = self.assetHistories.filter(history => history.categoryName === catName && history.subcategoryName).map(filterHistory => filterHistory.subcategoryName);
                    // Extract the array of ASSET subcategory names of the current category name
                    let peopleAssetSubcategories = [];
                    if (assetSubcategoriesByCategoryName[catName] && assetSubcategoriesByCategoryName[catName].length > 0) {
                        peopleAssetSubcategories = assetSubcategoriesByCategoryName[catName].map(subcategory => subcategory.name);
                    }
                    const peopleSubcategoriesNames = _.union(peopleAssetSubcategories, peopleAssetSubcatHistories);
                    if (peopleSubcategoriesNames.length > 0) {
                        peopleSubcategoryNamesByCategoryName[catName] = peopleSubcategoriesNames
                    }
                }
                // Init the select2 subcategories
                self.initPeopleSubcategorySelect2(peopleSubcategoryNamesByCategoryName);
                // Select2 were initialized
                self.isInitSelect2 = true;
            }
        },

        /**
         * Initialize asset categories select2.
         * @param {array} categoryNames   Array of category names as ["category1", "category2", ...]
         */
        initPeopleCategorySelect2(categoryNames) {
            console.log("Component(contactEvents)::initPeopleCategorySelect2() - called", categoryNames);
            var self = this;
            const selector = $("#contactEvents_categorySelect2");
            // Init Select2 data of category names
            let dataCategorySelect2 = categoryNames.map( (name) => {
                return {
                    id: name,
                    text: self.getTranslatedAssetKey(name)
                } ;
            });
            // Ascending sort order Select2 data by text
            dataCategorySelect2 = _.orderBy(
                dataCategorySelect2,
                [category => category.text.toLowerCase()],
                ["asc"]
            );
            // Init category names Select2
            commonVueHelper.destroySelect2(selector);

            selector
                .select2({
                    placeholder: i18n.t("analytics_contactEventsSelectCategory"),
                    width: "30%",
                    data: dataCategorySelect2,
                    //minimumResultsForSearch: -1,
                    language: {
                        noResults: function() {
                            return i18n.t("common_noResultsFound");
                        }
                    },
                    containerCssClass: "geonotifSelect2"
                })
                .val(this.vCategories)
                .trigger("change")
                // emit event on change.
                .on("change", function() {
                    self.vCategories = $(this).val();
                });
        },

        /**
         * Initialize asset subcategories select2.
         * @param {object} subcategoryNamesByCategoryName   List of subcategory names by category name as {"category1": ["subcat1", "subcat2", ..], "category2": ["subcat10", ...], ...}
         */
        initPeopleSubcategorySelect2(subcategoryNamesByCategoryName) {
            console.log("Component(contactEvents)::initPeopleSubcategorySelect2() - called", subcategoryNamesByCategoryName);
            var self = this;
            const selector = $("#contactEvents_subcategorySelect2");
            let dataGroup = {};
            // subcategoryNamesByCategoryName list as {"category1": ["subcat1", "subcat2", ..], "category2": ["subcat10", ...], ...}
            for (let categoryName in subcategoryNamesByCategoryName) {
                let childSubcategories = subcategoryNamesByCategoryName[categoryName].map(name => {
                    let childElement = {
                        id: name,
                        text: self.getTranslatedAssetKey(name)
                    }
                    return childElement;
                });
                if (!dataGroup[categoryName]) {
                    dataGroup[categoryName] = {text: self.getTranslatedAssetKey(categoryName)};
                } else {
                    dataGroup[categoryName].text = self.getTranslatedAssetKey(categoryName);
                }
                if (!dataGroup[categoryName].children) {
                    dataGroup[categoryName].children = [];
                }
                childSubcategories = _.orderBy(
                    childSubcategories,
                    [subcategory => subcategory.text.toLowerCase()],
                    ["asc"]
                );
                dataGroup[categoryName].children = dataGroup[categoryName].children.concat(childSubcategories);
            }
            // Ascending sort order Select2 data by text
            dataGroup = _.orderBy(
                dataGroup,
                [category => category.text.toLowerCase()],
                ["asc"]
            );
            // Init categories Select2
            commonVueHelper.destroySelect2(selector);

            selector
                .select2({
                    placeholder: i18n.t("analytics_contactEventsSelectSubcategory"),
                    width: "30%",
                    data: dataGroup,
                    //minimumResultsForSearch: -1,
                    // escapeMarkup: function(m) {
                    //     return m;
                    // },
                    containerCssClass: "geonotifSelect2"
                })
                .val(this.vSubcategories)
                .trigger("change")
                // emit event on change.
                .on("change", function() {
                    self.vSubcategories = $(this).val();
                });
        },

        getAssetDetailLabel(unit, tagSN, tagType, categoryName, isDefaultCategory, subcategoryName, isDefaultSubcategory) {
            // Format asset tag label as: (<category> [<subcategory] / <unit> / <tag SN> [tag type])
            let assetDetailLabel = "(";
            if (categoryName) {
                assetDetailLabel +=  this.getTranslatedAssetKey(categoryName, isDefaultCategory);
                if (subcategoryName) {
                    assetDetailLabel += " [" + this.getTranslatedAssetKey(subcategoryName, isDefaultSubcategory) + "]";
                }
            }
            if (unit) {
                assetDetailLabel += " / " + i18n.t(unit);
            }
            assetDetailLabel += " / ";
            if (tagSN) {
                assetDetailLabel += tagSN;
            }
            if (tagType) {
                assetDetailLabel += ' [' + i18n.t('analytics_' + tagType) + ']'; //analytics_ // i18n.t("analytics_contactEventsTreeName"),
            }
            assetDetailLabel += ")";
            return assetDetailLabel;
        },

        formatAssetItem(item) {
            if (!item.id) {
                return item.text;
            }
            let htmlItem = `<row><span>` + item.text + ` </span>`;
            if (item.detailText) {
                htmlItem += `<small>` + item.detailText + `</small></span>`
            }
            htmlItem += `</span></row>`;
            return htmlItem;
        },

        enableSelectedAnyone() {
            $("#contactEvents_assetSelect2").attr("disabled", "true");
            $("#contactEvents_categorySelect2").attr("disabled", "true");
            $("#contactEvents_subcategorySelect2").attr("disabled", "true");
        },

        enableSelectedAsset() {
            $("#contactEvents_assetSelect2").removeAttr("disabled");
            $("#contactEvents_categorySelect2").attr("disabled", "true");
            $("#contactEvents_subcategorySelect2").attr("disabled", "true");
        },

        enableSelectedCategory() {
            $("#contactEvents_assetSelect2").attr("disabled", "true");
            $("#contactEvents_categorySelect2").removeAttr("disabled");
            $("#contactEvents_subcategorySelect2").attr("disabled", "true");
        },

        enableSelectedSubcategory() {
            $("#contactEvents_assetSelect2").attr("disabled", "true");
            $("#contactEvents_categorySelect2").attr("disabled", "true");
            $("#contactEvents_subcategorySelect2").removeAttr("disabled");
        },

        isCorrectAssetSelected() {
            if (this.vSelectedAsset === "asset" && this.vAssetHistories.length > 0) {
                return true;
            }
            return false;
        },

        isCorrectAssetCategorySelected() {
            if (this.vSelectedAsset === "assetCategory" && this.vCategories.length > 0) {
                return true;
            }
            return false;
        },

        isCorrectAssetSubcategorySelected() {
            if (this.vSelectedAsset === "assetSubcategory" && this.vSubcategories.length > 0) {
                return true;
            }
            return false;
        },

        isCorrectContactSelected() {
            if (this.vSelectedAsset === "anything") {
                return true;
            }
            if (this.isCorrectAssetSelected()) {
                return true;
            }
            if (this.isCorrectAssetCategorySelected()) {
                return true;
            }
            if (this.isCorrectAssetSubcategorySelected()) {
                return true;
            }
            return false;
        },

        invalidSelectedAsset() {
            let isInvalid = false;
            switch (this.vSelectedAsset) {
                case "anything":
                    isInvalid = false;
                    break;
                case "asset":
                    if (this.vAssetHistories.length === 0) {
                        isInvalid = true;
                    }
                    break;
                case "assetCategory":
                    if (this.vCategories.length === 0) {
                        isInvalid = true;
                    }
                    break;
                case "assetSubcategory":
                    if (this.vSubcategories.length === 0) {
                        isInvalid = true;
                    }
                    break;
                default:
                    isInvalid = true;;
            }
            return isInvalid;
        },

        onSearchButton() {
            var self = this;
            let searchData = {
                siteId                 : self.siteId,
                startDate              : self.vStartTime.getTime(),    //commonVueHelper.dateToISOString(self.vStartTime),
                endDate                : self.vEndTime.getTime(),      //commonVueHelper.dateToISOString(self.vEndTime),
                filters                : {},
                minimalContactDuration : self.vMinimalDuration * 60,   // in seconds
                criticalContactDistance: self.vCriticalDistance
            };
            //self.isEmptyList = true; // To force reload of columnDefs
            switch (self.vSelectedAsset) {
                case "asset":
                    self.vCategories    = [];
                    self.vSubcategories = [];
                    searchData.filters.assets = self.vAssetHistories;
                    break;
                case "assetCategory":
                    self.vAssetHistories = [];
                    self.vSubcategories  = [];
                    searchData.filters.categories = self.vCategories;
                    break;
                case "assetSubcategory":
                    self.vAssetHistories = [];
                    self.vCategories     = [];
                    searchData.filters.subcategories = self.vSubcategories;
                    break;
                case "anything":
                default   :   // Defense
                    self.vAssetHistories = [];
                    self.vCategories     = [];
                    self.vSubcategories  = [];
            }

            self.getContactEvents(searchData);
        },

        // Function called when user click on the "Cancel" button
        onCancelButton() {
            console.log( "Component(contactEvents)::onCancelButton() - called");
            // Go back to the previous vue
            this.$router.go(-1);
        },

        // -----------------------
        // Data format
        // -----------------------

        formatDuration(durationInSeconds) {
            if (durationInSeconds) {
                let hours   = Math.floor(durationInSeconds / 3600);
                let minutes = Math.floor((durationInSeconds - (hours * 3600)) / 60);
                let seconds = (durationInSeconds - (hours * 3600) - (minutes * 60));

                if (hours > 0) {
                    if (minutes === 0 && seconds === 0) {
                        // Display only hours
                        return hours + hourUnitTranslated;
                    }
                    if (seconds === 0) {
                        // Display only hours and minutes
                        return hours + hourUnitTranslated + " " + minutes + minuteUnitTranslated;
                    }
                    // Display hours and minutes and seconds
                    return hours + hourUnitTranslated + " " + minutes + minuteUnitTranslated + " " + seconds + secondUnitTranslated;
                }

                if (minutes > 0) {
                    if (seconds === 0) {
                        // Display only minutes
                        return minutes + minuteUnitTranslated;
                    }
                    // Display only minutes and seconds
                    return minutes + minuteUnitTranslated + " " + seconds + secondUnitTranslated;
                }

                // Display only seconds
                return seconds + secondUnitTranslated;
            }

            if (durationInSeconds === 0) {
                return "-";
            }
        },

        formatDistance(distance) {
            if (distance !== undefined) {
                let newDistance = distance;
                if (newDistance < 0.5) {
                    newDistance = "< 0.5";
                }
                return newDistance + meterUnitTranslated;
            }
        },

        formatPercent(value) {
            if (value !== undefined && value !== null) {
                return value + " %";
            }
        },

        roundHalf(number) {
            if (number !== undefined && number !== null) {
                return Math.round(number * 2) / 2;
            }
        },

        getTranslatedAssetKey(value, isDefault) {
            if (value) {
                if (isDefault || (value.indexOf("ASSET_C_") === 0 || value.indexOf("ASSET_SC_") === 0) || value.indexOf("UNIT_") === 0) {
                    return i18n.t(value);
                }
            }
            return value;
        },


        // -----------------------
        // DataTable
        // -----------------------

        initContactEventsData() {
            console.log("Component(contactEvents)::initContactEventsData() - called ");
            if (this.contactEvents && this.contactEvents.contactsList) {
                this.contactEventsData = this.contactEvents.contactsList.map(contact => {
                    return {
                        // Parent contact
                        rootAssetHistoryId:         contact.rootAssetHistoryId,
                        rootAssetName:              contact.rootAssetName,
                        rootCategory:               this.getTranslatedAssetKey(contact.rootCategory, contact.rootIsDefaultCategory),
                        rootSubcategory:            this.getTranslatedAssetKey(contact.rootSubcategory, contact.rootIsDefaultSubcategory),
                        rootAssetUnit:              this.getTranslatedAssetKey(contact.rootAssetUnit),
                        rootTagSerialNumber:        contact.rootTagSerialNumber,
                        rootTagType:                (contact.rootTagTypeName ? i18n.t('analytics_' + contact.rootTagTypeName) : ""),
                        // Child contact
                        assetHistoryId:             contact.assetHistoryId,
                        assetName:                  contact.assetName,
                        category:                   this.getTranslatedAssetKey(contact.category, contact.isDefaultCategory),
                        subcategory:                this.getTranslatedAssetKey(contact.subcategory, contact.isDefaultSubcategory),
                        assetUnit:                  this.getTranslatedAssetKey(contact.assetUnit),
                        tagSerialNumber:            contact.tagSerialNumber,
                        tagType:                    (contact.tagTypeName ? i18n.t('analytics_' + contact.tagTypeName) : ""),
                        // Contact data
                        risk:                       (contact.risk ? contact.risk : 0),
                        startDate:                  contact.startDate,
                        endDate:                    contact.endDate,
                        geofences:                  (contact.geofences ? contact.geofences.join(", ") : ""),
                        minimalDistance:            contact.minimalDistance,
                        duration0to5m:              contact.duration0to5m,
                        duration5to10m:             contact.duration5to10m,
                        averageDistance:            contact.averageDistance,
                        totalDuration:            contact.totalDuration
                    };
                });
            } else {
                this.contactEventsData = null;
            }
            console.log("Component(contactEvents)::initContactEventsData() - result ", this.contactEventsData);
        },

        initKTDataTable() {
            var self = this;

            console.log("Component(contactEvents)::initKTDataTable() - called");

            // If site table has been already build, destroy the table before creating a fresh instance !
            if (this.contactEventsDataTable) {
                this.destroyKTDatatable();
            }

            if (this.contactEventsData) {

                // According to source list length, show datatable or empty list icon+text
                if (this.contactEventsData.length > 0) {
                    // There is data => Hide empty list icon+text
                    this.isEmptyList = false;

                    const options = {
                        data: {
                            type: "local",
                            source: commonVueHelper.preventHtmlInjection(this.contactEventsData),
                            pageSize: 10,
                            saveState: false
                        },
                        layout: {
                            theme: "default",
                            class: "",
                            scroll: true, // enable/disable datatable scroll both horizontal and vertical when needed.
                            height: 600,
                            footer: !1
                        },
                        translate: commonVueHelper.getTranslateOptions(),
                        toolbar: commonVueHelper.getToolbarOptions(),
                        sortable: !0,
                        pagination: !0,
                        search: {
                            //onEnter: true,                        // The internal search will be triggered using Enter button
                            input: $("#contactEvents_searchInput") // add onKeyup event to the input
                        },
                        rows: {
                            autoHide: false
                        },
                        columns: [
                            {
                                field: 'rootAssetName',
                                title: i18n.t("analytics_contactEventsAssetName"),
                                textAlign: "center",
                                template: function(row) {
                                    return '<span data-placement="right" data-toggle="kt-tooltip" data-original-title="' + row.rootAssetName + '">' + row.rootAssetName + '</span>';
                                }
                            },
                            {
                                field: 'rootCategory',
                                title: i18n.t("analytics_contactEventsAssetCategory"),
                                textAlign: "center"
                            },
                            {
                                field: 'rootSubcategory',
                                title: i18n.t("analytics_contactEventsAssetSubcategory"),
                                textAlign: "center"
                            },
                            {
                                field: 'rootAssetUnit',
                                title: i18n.t("analytics_contactEventsAssetUnit"),
                                width: columnWidthByName['unit'],
                                textAlign: "center",
                            },
                            {
                                field: 'rootTagSerialNumber',
                                title: i18n.t("analytics_contactEventsAssetTagSN"),
                                textAlign: "center",
                                template: function(row) {
                                    return '<span data-placement="right" data-toggle="kt-tooltip" data-original-title="' + row.rootTagSerialNumber + '">' + row.rootTagSerialNumber + '</span>';
                                }
                            },
                            {
                                field: 'rootTagType',
                                title: i18n.t("analytics_contactEventsAssetTagType"),
                                width: columnWidthByName['tagType'],
                                textAlign: "center"
                            },
                            {
                                field: 'assetName',
                                title: i18n.t("analytics_contactEventsPrimaryContactName"),
                                textAlign: "center",
                                template: function(row) {
                                    return '<span data-placement="right" data-toggle="kt-tooltip" data-original-title="' + row.assetName + '">' + row.assetName + '</span>';
                                }
                            },
                            {
                                field: 'category',
                                title: i18n.t("analytics_contactEventsPrimaryContactCategory"),
                                textAlign: "center"
                            },
                            {
                                field: 'subcategory',
                                title: i18n.t("analytics_contactEventsPrimaryContactSubcategory"),
                                textAlign: "center"
                            },
                            {
                                field: 'assetUnit',
                                title: i18n.t("analytics_contactEventsPrimaryContactUnit"),
                                width: columnWidthByName['unit'],
                                textAlign: "center",
                            },
                            {
                                field: 'tagSerialNumber',
                                title: i18n.t("analytics_contactEventsPrimaryContactTagSN"),
                                textAlign: "center",
                                template: function(row) {
                                    return '<span data-placement="right" data-toggle="kt-tooltip" data-original-title="' + row.tagSerialNumber + '">' + row.tagSerialNumber + '</span>';
                                }
                            },
                            {
                                field: 'tagType',
                                title: i18n.t("analytics_contactEventsPrimaryContactTagType"),
                                width: columnWidthByName['tagType'],
                                textAlign: "center"
                            },
                            {
                                field: "risk",
                                title: i18n.t("analytics_contactEventsRisk"),
                                width: columnWidthByName['risk'],
                                textAlign: "center",
                                template: function(row) {
                                    const risk = row.risk + " %";
                                    const riskLevel = (self.currentContacEventSetting ? self.currentContacEventSetting.riskLevel : 50);
                                    if (row.risk >= riskLevel) {
                                        return `<span style="color:red;">`+risk+`</span>`;
                                    }
                                    return risk;
                                }
                            },
                            {
                                field: "startDate",
                                title: i18n.t("analytics_contactEventsStartDate"),
                                width: columnWidthByName['date'],
                                textAlign: "center",
                                template: function(row) {
                                    if (row.startDate) {
                                        return moment(row.startDate).format(commonVueHelper.getDateStringFormatFromLocale(moment().locale()));
                                    }
                                }
                            },
                            {
                                field: "endDate",
                                title: i18n.t("analytics_contactEventsEndDate"),
                                width: columnWidthByName['date'],
                                textAlign: "center",
                                template: function(row) {
                                    if (row.endDate) {
                                        return moment(row.endDate).format(commonVueHelper.getDateStringFormatFromLocale(moment().locale()));
                                    }
                                }
                            },
                            {
                                field: 'geofences',
                                title:  i18n.t("analytics_contactEventsGeofences"),
                                textAlign: "center",
                            },
                            {
                                field: 'minimalDistance',
                                title: i18n.t("analytics_contactEventsMinimalDistance"),
                                width: columnWidthByName['distance'],
                                textAlign: "center",
                                template: function(row) {
                                    return self.formatDistance(row.minimalDistance);
                                }
                            },
                            {
                                field: 'duration0to5m',
                                title: i18n.t("analytics_contactEventsDuration0to5m"),
                                textAlign: "center",
                                width: columnWidthByName['duration'],
                                template: function(row) {
                                    return self.formatDuration(row.duration0to5m);
                                }
                            },
                            {
                                field: 'duration5to10m',
                                title: i18n.t("analytics_contactEventsDuration5to10m"),
                                textAlign: "center",
                                width: columnWidthByName['duration'],
                                template: function(row) {
                                    return self.formatDuration(row.duration5to10m);
                                }
                            },
                            {
                                field: 'averageDistance',
                                title: i18n.t("analytics_contactEventsAverageDistance"),
                                width: columnWidthByName['distance'],
                                textAlign: "center",
                                template: function(row) {
                                    return self.formatDistance(row.averageDistance);
                                }
                            },
                            {
                                field: 'totalDuration',
                                title: i18n.t("analytics_contactEventsTotalDuration"),
                                width: columnWidthByName['duration'],
                                textAlign: "center",
                                template: function(row) {
                                    return self.formatDuration(row.totalDuration);
                                }
                            },
                        ]
                    };

                    // Now, we are ready to build the table
                    this.contactEventsDataTable = $("#contactEvents_datatable").KTDatatable(options);
                    // Once table is build, register click events on buttons inside the table
                    this.registerEventsOnDataTable();
                } else {
                    // There is no data => Show empty list icon+text
                    // No table process: Comment lines below because table destroy previously
                    this.isEmptyList = true;
                }
            }
        },

        registerEventsOnDataTable() {
            var self = this;

            console.log("Component(contactEvents)::registerEventsOnDataTable() - called");

            $("#contactEvents_datatable").on("kt-datatable--on-init", (event, options) => {
                console.log("Component(contactEvents)::registerEventsOnDataTable() - Datatable init:", event);

            }).on("kt-datatable--on-layout-updated", (event, args) => {
                // Event that will be triggered on layout rendering update and resizing datatable.
                console.log("Component(contactEvents)::registerEventsOnDataTable() - Layout render updated:", event, args);
                // Force width pager size
                setTimeout(() => {
                    $(".kt-datatable__pager-size").css("width", "80px");
                    // Init tooltips
                    $('[data-toggle="kt-tooltip"]').tooltip({ trigger: "hover" });
                }, 50);

                // refresh column according to current filterd columns
                self.refreshFilteredColumns();

            }).on('kt-datatable--on-reloaded', function(event) {
                console.log("Component(contactEvents)::registerEventsOnDataTable() - Datatable reloaded:", event);

            }).on('kt-datatable--on-sort', function(event, args) {
                console.log("Component(contactEvents)::registerEventsOnDataTable() - Datatable sorted by " + args.field + " " + args.sort);

            });
        },

        destroyKTDatatable() {
            if (this.contactEventsDataTable) {
                console.log("Component(contactEvents)::destroyKTDatatable() - called");
                const selectorDatable = $("#contactEvents_datatable");
                if (selectorDatable) {
                    const currentKTDataTable = selectorDatable.KTDatatable();
                    if (currentKTDataTable) {
                        currentKTDataTable.destroy();
                    }
                }
                this.contactEventsDataTable = null;
            }
        },

        /**
         * Show or hide a named column of a datatable.
         * @param {bool} show
         * @param {string} columnName
         */
        showOrHideKTDatatableColumn(show, columnName) {
            if (this.contactEventsDataTable) {
                // Get the column API object
                let headColumn  = $('.kt-datatable__table .kt-datatable__head th.kt-datatable__cell[data-field="'+columnName+'"]');
                // let dataColumns = $('.kt-datatable__table .kt-datatable__body td.kt-datatable__cell[data-field="'+columnName+'"]');
                let isVisible = headColumn.is(":visible");

                if (show && !isVisible) {
                    // Show the column
                    this.contactEventsDataTable.showColumn(columnName);
                } else if (!show && isVisible) {
                    // Hide the column
                    this.contactEventsDataTable.hideColumn(columnName);
                }
            }
        },

        refreshFilteredColumns() {
            const inputCols = _.flatten(this.columnDefs).map(col => col.field);
            const selectedCols = _.flatten(this.filteredColumns).map(col => col.field);
            console.log("Component(contactEvents)::refreshFilteredColumns() - selectCols:", selectedCols);
            for (let idx in inputCols) {
                const columnName = inputCols[idx];
                const show = selectedCols.includes(columnName);
                this.showOrHideKTDatatableColumn(show, columnName);
            }
        },

        exportContacEventsDataToExcel() {
            var data = [];

            //  Creation of header
            const header = [
                {name: i18n.t("analytics_contactEventsAssetName"), type: "STRING"},
                {name: i18n.t("analytics_contactEventsAssetCategory"), type: "STRING"},
                {name: i18n.t("analytics_contactEventsAssetSubcategory"), type: "STRING"},
                {name: i18n.t("analytics_contactEventsAssetUnit"), type: "STRING"},
                {name: i18n.t("analytics_contactEventsAssetTagSN"), type: "STRING"},
                {name: i18n.t("analytics_contactEventsAssetTagType"), type: "STRING"},
                {name: i18n.t("analytics_contactEventsPrimaryContactName"), type: "STRING"},
                {name: i18n.t("analytics_contactEventsPrimaryContactCategory"), type: "STRING"},
                {name: i18n.t("analytics_contactEventsPrimaryContactSubcategory"), type: "STRING"},
                {name: i18n.t("analytics_contactEventsPrimaryContactUnit"), type: "STRING"},
                {name: i18n.t("analytics_contactEventsPrimaryContactTagSN"), type: "STRING"},
                {name: i18n.t("analytics_contactEventsPrimaryContactTagType"), type: "STRING"},
                {name: i18n.t("analytics_contactEventsRiskPercent"), type: "STRING"},
                {name: i18n.t("analytics_contactEventsStartDate"), type: "DATETIMEZONE"},
                {name: i18n.t("analytics_contactEventsEndDate"), type: "DATETIMEZONE"},
                {name: i18n.t("analytics_contactEventsGeofences"), type: "STRING"},
                {name: i18n.t("analytics_contactEventsMinimalDistanceM"), type: "FLOAT"},
                {name: i18n.t("analytics_contactEventsDuration0to5m"), type: "TIME"},
                {name: i18n.t("analytics_contactEventsDuration5to10m"), type: "TIME"},
                {name: i18n.t("analytics_contactEventsAverageDistanceM"), type: "FLOAT"},
                {name: i18n.t("analytics_contactEventsTotalDuration"), type: "TIME"}
            ];

            // Set Excel contacts data row
            this.contactEventsData.forEach(contact => {
                let contactInfoArray = [];

                for (const property in contact) {
                    switch (property) {
                        case 'rootAssetHistoryId':
                        case 'assetHistoryId':
                            // Not exported in Excel file
                            break;
                        case 'startDate':
                        case 'endDate':
                            contactInfoArray.push(moment(contact[property]).format(xlMomentDateFormat));
                            break;
                        case 'geofences':
                            contactInfoArray.push(contact[property]);
                            break;
                        case 'duration0to5m':
                        case 'duration5to10m':
                        case 'totalDuration':
                            contactInfoArray.push(moment.utc(contact[property] * 1000).format("HH:mm:ss"));
                            break;
                        default:
                            contactInfoArray.push(contact[property]);
                    }
                }
                // Add in export data
                data.push(contactInfoArray);
            });

            const title = "From " + moment(this.vStartTime).format(xlMomentDateFormat) +  " to " + moment(this.vEndTime).format(xlMomentDateFormat);

            // Parameters info to store in worksheet "Parameters"
            const infoHeader = [
                {name: i18n.t("analytics_contactEventsFromStartDate"), type: "DATETIMEZONE"},
                {name: i18n.t("analytics_contactEventsToEndDate"), type: "DATETIMEZONE"},
                {name: i18n.t("analytics_contactEventsMinimalDuration"), type: "TIME"},
                {name: i18n.t("analytics_contactEventsCriticalDistance") + " (" + i18n.t("common_shortMeter") + ")", type: "FLOAT"}
            ];
            let infoData = [];
            let firstInfoArray = [];
            firstInfoArray.push(moment(this.vStartTime).format(xlMomentDateFormat));
            firstInfoArray.push(moment(this.vEndTime).format(xlMomentDateFormat));
            firstInfoArray.push(moment.utc(this.vMinimalDuration * 60 * 1000).format("HH:mm:ss"));
            firstInfoArray.push(this.vCriticalDistance);
            infoData.push(firstInfoArray);

            // Call common vueHelper function to export data and parameters in an Excel file
            const fileName = "exportContactEvents_" + moment();
            const worksheetName = "ContactEvents";
            const worksheetParametersName = "Parameters";
            commonVueHelper.exportsDataToExcel(data, header, fileName, worksheetName, title, infoData, infoHeader, worksheetParametersName);
        },

        onContactEventSettings() {
            $("#contactEventSettingsModal").modal("show");
        }

    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
        "app-sitetitle": SiteTitle,
        "app-accesserrorportlet": AccessErrorPortlet,
        "app-emptylist": EmptyList,
        "app-select-columns": selectColumnsVue,
        "app-contacteventsettingsmodal": ContactEventSettingsModal
    }
};
</script>

<style scoped>
.baseTemplate {
    display: contents;
}
.mtop0 {
    margin-top: 0;
}
.mtop20 {
    margin-top: 20px;
}
.mbottom0 {
    margin-bottom: 0;
}
.contactParamInput {
    width: 223px;
}
</style>
