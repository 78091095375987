import { render, staticRenderFns } from "./contactevents.vue?vue&type=template&id=bd197d16&scoped=true&"
import script from "./contactevents.vue?vue&type=script&lang=js&"
export * from "./contactevents.vue?vue&type=script&lang=js&"
import style0 from "./contactevents.vue?vue&type=style&index=0&id=bd197d16&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bd197d16",
  null
  
)

export default component.exports